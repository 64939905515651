import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import ModalComponent from '../components/ModalComponent';
import { RootState, AppDispatch } from '../store/store';
import { logout } from '../reducers/authSlice';
import '../App.css';
import BuyPremium from "../components/BuyPremium";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../components/PaymentForm";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import NotificationModal from '../components/NotificationModal';
import api from "../api/axiosInstance";
import { useNavigate } from "react-router-dom";
import CookiesModal from "../components/CookiesModal";
import DeleteAccountModal from "../components/DeleteAccountModal";
//import PDFFile from '../assets/terms-of-service.pdf'; // Assuming the PDF is stored in src/assets folder

const stripePromise = loadStripe('pk_test_Tz9jitxXqadppVQR60EbUGM9');

const AccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
`;

const SmallLinkContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

const SmallLink = styled.a`
    font-size: 0.8em;
    color: #333;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 60px;
    width: 100%;
    justify-content: center;
    max-width: 400px;
    margin-bottom: 20px;
`;

const Button = styled.button`
    flex: 1;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #f0f0f0;
    color: #000;
`;

const FullWidthButton = styled(Button)`
    width: 100%;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

const PremiumButton = styled(FullWidthButton)`
    background: linear-gradient(45deg, #FF7F00, #f56607);
    color: white;

    &:hover {
        background: linear-gradient(45deg, #f56607, #FF7F00);
    }
`;

const Divider = styled.div`
    width: 80%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
`;

const LogoutButton = styled(FullWidthButton)`
    background: #ff4d4d;
    color: white;

    &:hover {
        background: #ff0000;
    }
`;

// Additional state for modals
const Account: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isRegisterOpen, setIsRegisterOpen] = useState(false);
    const [isPremiumModalOpen, setPremiumModalOpen] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState<StripeElementsOptions>({});
    const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
    const [isCookiesModalOpen, setCookiesModalOpen] = useState(false);
    const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

    const user = useSelector((state: RootState) => state.auth.user);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const handleLogout = () => {
        dispatch(logout());
    };

    const openPremiumModal = () => {
        setPremiumModalOpen(true);
    };

    const closePremiumModal = () => {
        setPremiumModalOpen(false);
    };

    const openNotificationModal = () => {
        setNotificationModalOpen(true);
    };

    const closeNotificationModal = () => {
        setNotificationModalOpen(false);
    };

    const openCookiesModal = () => {
        setCookiesModalOpen(true);
    };

    const closeCookiesModal = () => {
        setCookiesModalOpen(false);
    };

    const openDeleteAccountModal = () => {
        setDeleteAccountModalOpen(true);
    };

    const closeDeleteAccountModal = () => {
        setDeleteAccountModalOpen(false);
    };

    const handleDeleteAccount = async () => {
        try {
            await api.delete(`/account/delete/${user?.id}`);
            dispatch(logout());
            navigate('/'); // Redirect to home or login page after deletion
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    useEffect(() => {
        const createPaymentOptions = async () => {
            try {
                const response = await api.post('/payment-intent', {
                    method: 'POST',
                });

                const data = response.data;

                const options: StripeElementsOptions = {
                    clientSecret: data.clientSecret,
                    appearance: {
                    },
                };

                setPaymentOptions(options);
            } catch (error) {
                console.error('Error creating payment options:', error);
            }
        };

        createPaymentOptions();
    }, []);

    return (
        <AccountContainer>
            {user?.isPremium ? (
                <PremiumButton disabled>Prémium Aktivované</PremiumButton>
            ) : (
                <PremiumButton onClick={openPremiumModal}>KÚPIŤ PRÉMIUM</PremiumButton>
            )}
            <Divider />
            <FullWidthButton onClick={() => navigate('/profile-settings')}>Nastavenia profilu</FullWidthButton>
            <FullWidthButton onClick={() => navigate('/order-history')} className="btn-gradient">
                História objednávok
            </FullWidthButton>
            <FullWidthButton onClick={openNotificationModal} className="btn-gradient">Upozornenia</FullWidthButton>
            <Divider />
            {isAuthenticated && (
                <LogoutButton onClick={handleLogout}>Odhlásiť sa</LogoutButton>
            )}
            <SmallLinkContainer>
                <SmallLink href="/sample.pdf" target="_blank" rel="noopener noreferrer">Podmienky použivania</SmallLink>
                <SmallLink onClick={openCookiesModal}>Nastavenia cookies</SmallLink>
                <SmallLink onClick={openDeleteAccountModal}>Odstraniť účet</SmallLink>
            </SmallLinkContainer>
            {paymentOptions?.clientSecret && (
                <Elements stripe={stripePromise} options={paymentOptions}>
                    <BuyPremium
                        isOpen={isPremiumModalOpen}
                        onClose={closePremiumModal}
                        stripePromise={stripePromise}
                        paymentOptions={paymentOptions}
                    />
                </Elements>
            )}
            <NotificationModal
                isOpen={isNotificationModalOpen}
                onRequestClose={closeNotificationModal}
                onSettingsUpdate={() => { /* Handle settings update here */ }}
            />
            <CookiesModal
                isOpen={isCookiesModalOpen}
                onRequestClose={closeCookiesModal}
            />
            <DeleteAccountModal
                isOpen={isDeleteAccountModalOpen}
                onRequestClose={closeDeleteAccountModal}
                onDeleteAccount={handleDeleteAccount}
            />
        </AccountContainer>
    );
};

export default Account;
