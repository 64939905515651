import React, { useState } from 'react';
import styled from 'styled-components';
import { FaHome, FaBell, FaEnvelope, FaUser } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import RegisterModal from '../components/RegisterModal';
import LoginModal from '../components/LoginModal';
import { RootState } from '../store/store';
import '../App.css';
import {FaMessage} from "react-icons/fa6";

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    padding: 10px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const IconButton = styled.button`
    background-color: transparent;
    border: none;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    font-size: 24px;
    color: #007bff;

    &:hover {
        color: #0056b3;
    }
`;

const Button = styled.button`
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const Header = () => {
    const [isChoiceModalOpen, setIsChoiceModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const user = useSelector((state: RootState) => state.auth.user);
    const navigate = useNavigate();

    const handleIconClick = (path: string) => {
        if (path === '/') {
            navigate('/');
        } else if (user) {
            navigate(path);
        } else {
            setIsChoiceModalOpen(true);
        }
    };

    const handleLogin = () => {
        setIsChoiceModalOpen(false);
        setIsLoginModalOpen(true);
    };

    const handleRegister = () => {
        setIsChoiceModalOpen(false);
        setIsRegisterModalOpen(true);
    };

    return (
        <HeaderContainer>
            <IconButton onClick={() => handleIconClick('/')}><FaHome className="icon-gradient" /></IconButton>
            <IconButton onClick={() => handleIconClick('/comment-notification')}><FaBell className="icon-gradient" /></IconButton>
            <IconButton onClick={() => handleIconClick('/messages')}><FaMessage className="icon-gradient" /></IconButton>
            <IconButton onClick={() => handleIconClick('/account')}><FaUser className="icon-gradient" /></IconButton>

            <Modal
                isOpen={isChoiceModalOpen}
                onRequestClose={() => setIsChoiceModalOpen(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        position: 'relative',
                        inset: 'auto',
                        padding: '20px',
                        borderRadius: '10px',
                        backgroundColor: '#fff',
                        maxWidth: '400px',
                        width: '90%',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }
                }}
                ariaHideApp={false}
            >
                <div style={{ textAlign: 'center' }}>
                    <Button style={{ marginRight: '50px' }} onClick={handleLogin}>Prihlásiť sa</Button>
                    <Button onClick={handleRegister}>Registrovať</Button>
                </div>
            </Modal>

            <LoginModal
                isOpen={isLoginModalOpen}
                onRequestClose={() => setIsLoginModalOpen(false)}
                onLogin={() => {
                    setIsLoginModalOpen(false);
                    navigate('/account');
                }}
            />
            <RegisterModal
                isOpen={isRegisterModalOpen}
                onRequestClose={() => setIsRegisterModalOpen(false)}
                onRegister={() => {
                    setIsRegisterModalOpen(false);
                    navigate('/account');
                }}
            />
        </HeaderContainer>
    );
};

export default Header;
