import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useDispatch } from "react-redux";
import { loginSuccess } from "../reducers/authSlice";
import api from "../api/axiosInstance";

// Image URL from the public folder
const imageSrc = `${process.env.PUBLIC_URL}/g3.png`;

interface RegisterModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onRegister: () => void;
}

const FormContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
`;

const LabelTerms = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 0.9em;  /* Adjust font size here */

`;

const Input = styled.input`
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const Button = styled.button<{ disabled?: boolean }>`
    margin: 10px 0;
    padding: 10px 20px;
    background-color: ${props => (props.disabled ? '#ccc' : '#007bff')};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

    &:hover {
        background-color: ${props => (props.disabled ? '#ccc' : '#0056b3')};
    }
`;

const Header = styled.h2`
    text-align: center;
`;

const ErrorMessage = styled.p`
    color: red;
    text-align: center;
`;

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

const Divider = styled.hr`
    border: none;
    border-top: 1px solid #f56607;
    margin: 20px auto; /* This centers the divider */
    width: 80%;
    background-color: #f56607;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
`;

const Checkbox = styled.input`
    margin-right: 10px;
`;

const TermsLink = styled.a`
    color: #f56607;
    text-decoration: underline;
    font-size: 0.9em;  /* Adjust font size here */
    &:hover {
        color: #ff7f00;
    }
`;


const RegisterModal: React.FC<RegisterModalProps> = ({ isOpen, onRequestClose, onRegister }) => {
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [role] = useState('USER'); // default role
    const [error, setError] = useState<string | null>(null);
    const [isTermsChecked, setIsTermsChecked] = useState(false); // New state for checkbox

    const dispatch = useDispatch();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (password !== password2) {
            setError("Heslá sa nezhodujú");
            return;
        }

        if (!isTermsChecked) {
            setError("Musíte súhlasiť s podmienkami používania.");
            return;
        }

        try {
            const response = await api.post('/v1/auth/register', {
                email,
                password,
                role,
                fullName
            });
            const { accessToken, refreshToken, user } = response.data;
            dispatch(loginSuccess({ accessToken, refreshToken, user }));
            onRequestClose();
            onRegister();
        } catch (error: any) {
            if (error.response && error.response.status === 409) {
                setError("Používateľ s týmto emailom už existuje.");
            } else {
                setError("Registrácia zlyhala. Skúste to znova.");
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="custom-modal-content"
            style={customStyles}
            ariaHideApp={false}
        >
            <FormContainer>
                <Header>Registrácia</Header>
                <Form onSubmit={handleSubmit}>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    <Label>Email</Label>
                    <Input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Label>Celé meno</Label>
                    <Input
                        type="text"
                        placeholder="Celé meno"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                    <Label>Heslo</Label>
                    <Input
                        type="password"
                        placeholder="Heslo"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Label>Zopakuj heslo</Label>
                    <Input
                        type="password"
                        placeholder="Zopakuj heslo"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                    <CheckboxContainer>
                        <Checkbox
                            type="checkbox"
                            checked={isTermsChecked}
                            onChange={(e) => setIsTermsChecked(e.target.checked)}
                        />
                        <LabelTerms>
                            Súhlasím s{" "}
                            <TermsLink href="/sample.pdf" target="_blank" rel="noopener noreferrer">
                                Obchodnými podmienkami a podmienkami používania.
                            </TermsLink>
                        </LabelTerms>
                    </CheckboxContainer>
                    <Divider />
                    <Button type="submit" disabled={!isTermsChecked}>
                        Registrovať
                    </Button>
                </Form>
            </FormContainer>
        </Modal>
    );
};

export default RegisterModal;
