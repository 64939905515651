import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    width: 100%;
    max-width: 600px;
`;

const Logo = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 20px;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const CommunityName = styled.h1`
    font-size: 2em;
    margin-top: 10px;
`;

const Description = styled.p`
    font-size: 1em;
    margin-top: 11px;
    padding: 0 20px;
    width: 100%;
    max-width: 350px;
`;

const Stats = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
    //color: #f56607;

    span {
        font-size: 0.9em;
    }
`;

const ProfileHeader = () => {
    return (
        <Header>
            <Logo src="./g.png" alt="Community Logo" />
            <Info>
                <CommunityName>Góly esá športová komunita</CommunityName>
                <Description>
                    Pre všetkých vášnivých fanúšikov a tipérov.
                    Cenné rady a informácie, reporty, analýzy, predikcie
                    v 🎾 a ⚽️ v podcaste 📽️ ktoré posunú tvoj prehľad 🆙📖
                </Description>
                <Stats>
                </Stats>
            </Info>
        </Header>
    );
};

export default ProfileHeader;
