import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../store/store';
import api from "../api/axiosInstance";
import {updateUser} from "../reducers/authSlice";  // Adjust the import path as per your project structure

const Container = styled.div`
    padding: 20px;
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
`;

const Heading = styled.h2`
    margin: 0;
    font-size: 24px;
`;

const Label = styled.label`
    margin-bottom: 10px;
    display: block;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
`;

const SaveButton = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const EditProfile: React.FC = () => {
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();

    // Initialize state with user's current profile information
    const [fullName, setFullName] = useState(user?.fullName || '');
    const [email, setEmail] = useState(user?.email || '');

    useEffect(() => {
        // Update state when user data changes
        if (user) {
            setFullName(user.fullName);
            setEmail(user.email);
        }
    }, [user]);

    const handleSave = async () => {
        try {
            const accountDto = {
                fullname: fullName,
                email: email,
            };

            const response = await api.put(`/account/email/${user?.id}`, accountDto);

            // Assuming the backend responds with the updated user data
            if (response && response.data) {
                dispatch(updateUser(response.data));
                alert('Zmena údajov bola úspešná');
                navigate(-1); // Go back to the previo
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            // Handle error (e.g., display an error message to the user)
        }
    };

    return (
        <Container>
            <Header>
                <BackButton onClick={() => navigate(-1)}>&larr;</BackButton>
                <Heading>Upraviť profil</Heading>
            </Header>
            <Label>Meno</Label>
            <Input
                type="text"
                placeholder="Meno"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
            />
            <Label>Email</Label>
            <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <SaveButton onClick={handleSave}>Uložiť</SaveButton>
        </Container>
    );
};

export default EditProfile;
