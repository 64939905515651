import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store/store';
import api from "../api/axiosInstance";
import OnlyPremium from './OnlyPremium'; // Import the OnlyPremium component

const Container = styled.div`
    padding: 20px;
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 20px;
`;

const Heading = styled.h2`
    margin: 0;
    font-size: 24px;
    margin-bottom: 20px;
`;

const TableContainer = styled.div`
    margin-top: 20px;
    overflow-x: auto; /* Enable horizontal scrolling */
    width: 100%; /* Make sure it takes full width on smaller screens */

    @media (min-width: 768px) {
        overflow-x: visible; /* Ensure it doesn't scroll on larger screens */
        width: 560px; /* Set width to match table on larger screens */
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    min-width: 560px; /* Ensure a minimum width for the table */
`;

const TableHead = styled.thead`
    background-color: #f0f0f0;
`;

const TableRow = styled.tr``;

const TableCell = styled.th`
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
`;

const TableBody = styled.tbody``;

const TableCellBody = styled.td`
    padding: 10px;
    border: 1px solid #ddd;
`;

const Button = styled.button`
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

interface OrderHistory {
    id: number;
    invoiceDate: string;
    totalAmount: number;
    status: string;
}

const OrderHistory: React.FC = () => {
    const [orders, setOrders] = useState<OrderHistory[]>([]);
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await api.get(
                    user?.isAdmin ? '/orders/all' : `/orders/${user?.id}`
                );
                setOrders(response.data);
            } catch (error) {
                console.error('Error fetching order history:', error);
            }
        };

        fetchOrders();
    }, [user]);

    const handleDownloadPdf = (orderId: number) => {
        api({
            url: `/orders/pdf/${orderId}`,
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice_${orderId}.pdf`); // or whatever you want the file name to be
            document.body.appendChild(link);
            link.click();
        });
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Format date to YYYY-MM-DD
    };

    // Conditional rendering: Check if the user is not premium
    if (!user?.isPremium) {
        return <OnlyPremium />;
    }

    return (
        <Container>
            <Header>
                <BackButton onClick={() => navigate(-1)}>&larr;</BackButton>
                <Heading>História objednávok</Heading>
            </Header>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Dátum</TableCell>
                            <TableCell>Celková cena</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Stiahnuť faktúru</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order, index) => (
                            <TableRow key={order.id}>
                                <TableCellBody>{index + 1}</TableCellBody>
                                <TableCellBody>{formatDate(order.invoiceDate)}</TableCellBody>
                                <TableCellBody>{order.totalAmount} €</TableCellBody>
                                <TableCellBody>{order.status}</TableCellBody>
                                <TableCellBody>
                                    <Button onClick={() => handleDownloadPdf(order.id)}>
                                        Stiahnuť faktúru
                                    </Button>
                                </TableCellBody>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default OrderHistory;
