import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    padding: 20px;
    position: relative;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
`;

const Heading = styled.h2`
    margin: 0;
    font-size: 24px;
`;

const FullWidthButton = styled.button`
    width: 100%;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background: #f0f0f0;
    color: #000;
    margin-top: 20px;
`;

const ProfileSettings: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Header>
                <BackButton onClick={() => navigate(-1)}>&larr;</BackButton>
                <Heading>Nastavenia profilu</Heading>
            </Header>
            <FullWidthButton onClick={() => navigate('/edit-profile')}>Upraviť profil</FullWidthButton>
            <FullWidthButton onClick={() => navigate('/change-password')}>Zmeniť heslo</FullWidthButton>
            <FullWidthButton onClick={() => navigate('/change-billing-details')}>Zmeniť fakturačné údaje</FullWidthButton>
        </Container>
    );
};

export default ProfileSettings;
