import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { FaCheckCircle, FaShoppingCart } from 'react-icons/fa';
import '../App.css';
import axios from "axios";
import {Elements, PaymentElement} from '@stripe/react-stripe-js';
import {Stripe, StripeElementsOptions} from "@stripe/stripe-js";
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import api from "../api/axiosInstance";


const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const Modal = styled.div`
    background: white;
    width: 600px;
    max-width: 90%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
    font-size: 1.5em;
    margin: 0;
    display: flex;
    align-items: center;
`;

const CartIcon = styled(FaShoppingCart)`
    margin-right: 10px;
`;

const PriceText = styled.div`
    font-size: 0.9em;
    color: #777;
`;

const StepNav = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column; /* Stack the items vertically */
        align-items: flex-start; /* Align items to the start */
    }
`;

const StepItem = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    color: ${(props) => (props.active ? '#007bff' : '#ccc')};
    position: relative;

    &::after {
        color: #ccc;
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        display: ${(props) => (props.active ? 'none' : 'block')};
    }

    &:last-child::after {
        content: "";
    }
`;

const CheckIcon = styled(FaCheckCircle)`
    color: #007bff;
    margin-right: 8px;
`;

const ModalContent = styled.div`
    margin-bottom: 20px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = styled.button`
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const ModalStep = styled.div`
    border-top: 1px solid #ddd;
    padding: 10px 0;
`;

const StepTitle = styled.h3`
    font-size: 1.2em;
    margin: 10px 0;
`;

const ProductContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
`;

const ProductImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background-color: #e0e0e0; /* Placeholder background color */
`;

const ProductInfo = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ProductName = styled.p`
    font-size: 1.2em;
    margin: 0;
    font-weight: bold;
    text-align: center;
`;

const QuantitySelector = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`;

const QuantityButton = styled.button`
    background-color: #f0f0f0;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1em;
    &:hover {
        background-color: #ddd;
    }
`;

const QuantityInput = styled.input`
    width: 40px;
    text-align: center;
    margin: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const ProductPriceContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ProductPrice = styled.p`
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-left: 20px;
`;

const TotalPrice = styled.p`
    font-size: 1.5em;
    font-weight: bold;
    margin: 20px 0 5px 0;
    text-align: right;
`;

const PriceDetail = styled.p`
    font-size: 0.9em;
    color: #777;
    text-align: right;
    margin: 0;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
`;

const SmallDivider = styled.div`
    width: 14%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
    margin-left: auto; /* This will align the divider to the right */
`;

const ModalTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
`;

const Input = styled.input`
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const ErrorMessage = styled.label`
    margin-top: 20px;
    font-weight: bold;
    display: block; // Ensure it behaves like a block element, not inline
    text-align: center;
    color: red;
`;

interface ModalComponentProps {
    isOpen: boolean;
    onClose: () => void;
    stripePromise: Promise<Stripe | null>;
    paymentOptions: StripeElementsOptions;
}

const BuyPremium: React.FC<ModalComponentProps> = ({ isOpen, onClose, stripePromise, paymentOptions }) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const theFullName = user ? user.fullName : '';
    const userId = user ? user.id : null;
    const baseURL = api.defaults.baseURL;

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [fullName, setFullName] = useState(theFullName);
    const [currentStep, setCurrentStep] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const pricePerItem = 9;
    const totalPrice = pricePerItem * quantity;
    const [streetAddress, setStreetAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');

    const [billingDetails, setBillingDetails] = useState({
        fullName: '',
        streetAddress: '',
        postcode: '',
        city: '',
        country: ''
    });

    const [showMissingFieldsMessage, setShowMissingFieldsMessage] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const [missingFields, setMissingFields] = useState({
        fullName: false,
        streetAddress: false,
        postcode: false,
        city: false,
        country: false,
    });

    const handleIncreaseQuantity = () => {
        setQuantity((prev) => prev + 1);
    };

    const handleDecreaseQuantity = () => {
        setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
    };

    const validateStep = () => {
        if (currentStep === 1) {
            const newMissingFields = {
                fullName: fullName.trim() === "",
                streetAddress: streetAddress.trim() === "",
                postcode: postcode.trim() === "",
                city: city.trim() === "",
                country: country.trim() === "",
            };

            setMissingFields(newMissingFields);

            // Check if any field is missing
            const hasMissingFields = Object.values(newMissingFields).includes(true);
            setShowMissingFieldsMessage(hasMissingFields);

            return !hasMissingFields;
        }

        return true; // If not step 1, allow the step change
    };


    const handleNextStep = async () => {
        if (validateStep()) {
            if (currentStep === 1) {
                try {
                    const response = await api.put(`/account/${userId}`, {
                        fullname: fullName,
                        streetAddress: streetAddress,
                        zipcode: postcode,
                        city: city,
                        country: country,
                    });
                } catch (error) {
                }
            }

            setCurrentStep(currentStep + 1);
        }
    };



    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const steps = [
        "Baliček",
        "Fakturačné Údaje",
        "Rekapitulácia",
        "Platba"
    ];

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // Now, proceed with the payment confirmation
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'http://localhost:3000/payment-status',  // You can leave it as an empty string if you handle redirection manually
            },
        });

        if (error) {
            setErrorMessage(error.message || 'An unexpected error occurred.');
        }
    };

    useEffect(() => {
        if (userId) {
            const fetchBillingDetails = async () => {
                try {
                    const response = await api.get(`/account/${userId}`);
                    const { fullName, streetAddress, zipcode, city, country } = response.data;
                    setBillingDetails({
                        fullName: fullName || '',
                        streetAddress: streetAddress || '',
                        postcode: zipcode || '',
                        city: city || '',
                        country: country || ''
                    });
                    setFullName(fullName || '');
                    setStreetAddress(streetAddress || '');
                    setPostcode(zipcode || '');
                    setCity(city || '');
                    setCountry(country || '');
                } catch (error) {
                    console.error('Error fetching billing details:', error);
                }
            };
            fetchBillingDetails();
        }
    }, [userId]);

    if (!isOpen) return null;

    return (
        <Overlay onClick={onClose}>
            <Modal onClick={(e) => e.stopPropagation()}>
                <ModalHeader>
                    <ModalTitleContainer>
                        <ModalTitle>
                            <CartIcon />
                            Košík
                        </ModalTitle>
                        <PriceText>{totalPrice} Eur</PriceText>
                    </ModalTitleContainer>
                </ModalHeader>
                <StepNav>
                    {steps.map((step, index) => (
                        <StepItem key={index} active={currentStep === index}>
                            {currentStep >= index ? <CheckIcon /> : null}
                            {step}
                        </StepItem>
                    ))}
                </StepNav>
                <ModalContent>
                    {currentStep === 0 && (
                        <ModalStep>
                            <ProductContainer>
                                <ProductImage src="https://via.placeholder.com/100" alt="Product" />
                                <ProductInfo>
                                    <ProductName>Premium Balíček</ProductName>
                                    <QuantitySelector>
                                        <QuantityButton onClick={handleDecreaseQuantity}>-</QuantityButton>
                                        <QuantityInput
                                            type="text"
                                            value={quantity}
                                            readOnly
                                        />
                                        <QuantityButton onClick={handleIncreaseQuantity}>+</QuantityButton>
                                    </QuantitySelector>
                                </ProductInfo>
                                <ProductPriceContainer>
                                    <ProductPrice>{totalPrice} Eur</ProductPrice>
                                </ProductPriceContainer>
                            </ProductContainer>
                            <Divider />
                        </ModalStep>
                    )}
                    {currentStep === 1 && (
                        <ModalStep>
                            <StepTitle>Fakturačné Údaje</StepTitle>

                            <Label>Celé Meno</Label>
                            <Input
                                type="text"
                                placeholder="Meno"
                                value={fullName}
                                onChange={(e) => {
                                    setFullName(e.target.value);
                                    setMissingFields({ ...missingFields, fullName: false });
                                }}
                                className={missingFields.fullName ? 'input-error' : ''}
                            />

                            <Label>Adresa</Label>
                            <Input
                                type="text"
                                placeholder="Adresa"
                                value={streetAddress}
                                onChange={(e) => {
                                    setStreetAddress(e.target.value);
                                    setMissingFields({ ...missingFields, streetAddress: false });
                                }}
                                className={missingFields.streetAddress ? 'input-error' : ''}
                            />

                            <Label>PSČ</Label>
                            <Input
                                type="text"
                                placeholder="PSČ"
                                value={postcode}
                                onChange={(e) => {
                                    setPostcode(e.target.value);
                                    setMissingFields({ ...missingFields, postcode: false });
                                }}
                                className={missingFields.postcode ? 'input-error' : ''}
                            />

                            <Label>Mesto</Label>
                            <Input
                                type="text"
                                placeholder="Mesto"
                                value={city}
                                onChange={(e) => {
                                    setCity(e.target.value);
                                    setMissingFields({ ...missingFields, city: false });
                                }}
                                className={missingFields.city ? 'input-error' : ''}
                            />

                            <Label>Krajina</Label>
                            <Input
                                type="text"
                                placeholder="Krajina"
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value);
                                    setMissingFields({ ...missingFields, country: false });
                                }}
                                className={missingFields.country ? 'input-error' : ''}
                            />

                            {showMissingFieldsMessage && (
                                <div style={{ color: 'red', marginTop: '10px' }}>
                                    Vyplňte všetky položky
                                </div>
                            )}
                        </ModalStep>
                    )}
                    {currentStep === 2 && (
                        <ModalStep>
                            <StepTitle>Rekapitulácia</StepTitle>
                            <ProductContainer>
                                <ProductImage src="https://via.placeholder.com/100" alt="Product" />
                                <ProductInfo>
                                    <ProductName>Premium Balíček</ProductName>
                                    <QuantitySelector>
                                        <QuantityInput
                                            type="text"
                                            value={quantity}
                                            readOnly
                                        />
                                    </QuantitySelector>
                                </ProductInfo>
                                <ProductPriceContainer>
                                    <ProductPrice>{totalPrice} Eur</ProductPrice>
                                </ProductPriceContainer>
                            </ProductContainer>
                            <Divider />
                            <TotalPrice>Celková cena: {totalPrice} Eur</TotalPrice>
                            <PriceDetail>Vrátane DPH</PriceDetail>
                            <SmallDivider />
                        </ModalStep>
                    )}
                    {currentStep === 3 && (
                        <ModalStep>
                            <StepTitle>Platba</StepTitle>
                            <PaymentElement />
                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </ModalStep>
                    )}
                </ModalContent>
                <ModalFooter>
                    {currentStep > 0 && (
                        <Button onClick={handlePreviousStep}>Späť</Button>
                    )}
                    {currentStep < steps.length - 1 ? (
                        <Button className="btn-gradient-next" onClick={handleNextStep}>
                            Ďalej
                        </Button>
                    ) : (
                        <Button onClick={(e) => handleSubmit(e as any)}>Dokončiť</Button>
                    )}
                </ModalFooter>

            </Modal>
        </Overlay>

    );
};

export default BuyPremium;