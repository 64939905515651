import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../reducers/authSlice';
import api from "../api/axiosInstance";

interface LoginModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onLogin: (data: any) => void;
}

const FormContainer = styled.div`
    width: 100%;
    margin: 0 auto;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Input = styled.input`
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ddd;
`;

const Button = styled.button`
    margin: 10px 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;

const Header = styled.h2`
    text-align: center;
`;

const ErrorMessage = styled.p`
    color: red;
    text-align: center;
`;

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

const Label = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
`;

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onRequestClose, onLogin }) => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await api.post('/v1/auth/authenticate', { email, password });
            const { accessToken, refreshToken, user } = response.data;
            dispatch(loginSuccess({ accessToken, refreshToken, user }));
            onLogin(response.data);
            onRequestClose();
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                setError("Zadané meno alebo heslo je nesprávne");
            } else {
                setError("Prihlásenie zlyhalo. Skúste to znova.");
            }
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="custom-modal-content"
            style={customStyles}
            ariaHideApp={false}
        >
            <Header>Prihlásiť sa</Header>
            <FormContainer>
                <Form onSubmit={handleSubmit}>
                    <Label>Email</Label>
                    <Input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Label>Heslo</Label>
                    <Input
                        type="password"
                        placeholder="Heslo"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    <Button type="submit">Potvrdiť</Button>
                </Form>
            </FormContainer>
        </Modal>
    );
};

export default LoginModal;
